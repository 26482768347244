import React from 'react'
import { Typography, Card } from "@mui/material";
import Layout from '../layouts';

const ClientsPage = () => (
    <Layout pageName="Clients">
        <Card style={{padding:'20px'}}>
            <Typography variant="h6" style={{color:'#080',fontWeight:'bold'}}>Some of our clients include:</Typography>

            <ul>
                <li>Children's Hospital and Regional Medical Center</li>
                <li>JP Morgan Chase</li>
                <li>Washington Mutual</li>
                <li>University of Washington</li>
                <li>Bank of America</li>
                <li>Casey Family Programs</li>
                <li>University of Puget Sound</li>
                <li>Fred Hutchinson Cancer Research Center</li>
                <li>Providence Hospital and Medical Center</li>
                <li>Swedish Hospital and Medical Center</li>
                <li>Seafirst Bank</li>
                <li>Westfarm Foods</li>
                <li>Boeing</li>
                <li>Nordstrom</li>
                <li>Drake Beam Morin</li>
                <li>Lee Hecht and Harrison</li>
                <li>Clay and Associates</li>
                <li>Westin Hotels</li>
                <li>IBM</li>
                <li>Seattle School District</li>
                <li>Edmonds Community College</li>
                <li>Woodland Park Zoo</li>
            </ul>

            <Typography variant="h6" style={{color:'#080',fontWeight:'bold'}}>What our clients have to
                say...</Typography>

            <br/>
            <div>
                <blockquote style={{margin:'0 auto',textAlign:'center'}}>"I was highly
                    impressed with the outstanding quality of Helene Cho's consulting services. She is a dynamic
                    and effective trainer and coach, who is passionate about her work and deeply cares about helping
                    professionals and organizations achieve their goals. She was a great gift to our staff and has helped
                    us create positive change and a pleasant, productive work environment. Helene is one of the best out
                    there!"
                    <br/><br/><small><em style={{color:'#080',fontWeight:'bold'}}>- Mary Smith, Administrator, University
                        of Washington</em></small></blockquote>
            </div>

            <br/>
            <br/>
            <div>
                <blockquote style={{margin:'0 auto',textAlign:'center'}}>"Helene delivers
                    results! She is incredibly flexible and customer service oriented. She is a professional,
                    enthusiastic, and practical consultant and trainer who makes the learning experience fun and effective.
                    Her experience and skills as a leader and a change agent combined with her dedication for meeting her
                    clients' needs make her a great asset to any organization!"
                    <br/><br/><small><em style={{color:'#080',fontWeight:'bold'}}>- Cynthia Clay, CEO and
                        Founder of NetSpeed Leadership</em></small></blockquote>
            </div>

            <br/>
            <br/>
            <div>
                <blockquote style={{margin:'0 auto',textAlign:'center'}}>"Helene embodies authenticity, professionalism,
                    and excellent customer service. She is a dynamic trainer and consultant who blends her knowledge,
                    experience, and caring approach in her work with people and organizations. She is highly skilled in
                    identifying needs and meeting them to produce results."
                    <br/><br/><small><em style={{color:'#080',fontWeight:'bold'}}>- Tracy Patterson, Manager of Casey
                        Family Programs — Leadership Development</em></small></blockquote>
            </div>

            <br/>
            <br/>

            <div>
                <blockquote style={{color:'#080',maxWidth:'400px',margin:'0 auto',textAlign:'center'}}>"Never doubt that
                    a group of committed citizens can change the world.
                    Indeed, it is the only thing that ever has."
                    <br/><br/><small><em>- Margaret Meade</em></small></blockquote>
            </div>

        </Card>
    </Layout>
);

export default ClientsPage;
